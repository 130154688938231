import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-tailwind/react/Button";
import { useToast } from "@chakra-ui/react";
import countryList from "country-list";
import DetailSteps from "../../../shared/Details/DetailSteps";
import "./BasicDetails1.css";
import {
    detailsActions,
    getIndustries,
} from "../../../data/reducers/details/details";

import { getEarlyUserService } from "../../../data/services/auth/auth";
import { getIndustriesService } from "../../../data/services/details/details";
import { Navigate, useNavigate } from "react-router";

const BasicDetails1 = ({ activeStep, setPage }) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const countries = countryList.getCodeList();

    const { industries, basicDetails1 } = useSelector((state) => state.details);
    const [data, setData] = useState({
        companyName: basicDetails1?.companyName,
        about: basicDetails1?.about,
        maturityLevel: basicDetails1?.maturityLevel,
        industryType: basicDetails1?.industryType,
        location: basicDetails1?.location,
        otherLocation: basicDetails1?.otherLocation,
        abn: basicDetails1?.abn,
        otherIndustry: basicDetails1?.otherIndustry,
    });

    const { user } = useSelector((state) => state.auth);
    const onChange = (e) => {
        console.log(e.target.value);
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const [nameError, setNameError] = useState(false);
    const [aboutError, setAboutError] = useState(false);
    // const [maturityError, setMaturityError] = useState(false);
    const [industryError, setIndustryError] = useState(false);
    //const [abnError, setABNError] = useState(false);
    const [locationError, setLocationError] = useState(false);

    const [suggestionSearch, setSuggestionSearch] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);

    const onSuggestionClick = (val) => {
        setData({ ...data, otherIndustry: val });
        setSuggestionSearch(val);
        setShowSuggestions(false);
    };

    const onSearchSuggestion = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setSuggestionSearch(e.target.value);
        setShowSuggestions(true);
    };

    useEffect(() => {
        if (user?.reset) {
            navigate("/auth/change-password");
        }
    }, [user]);

    useEffect(() => {
        dispatch(getIndustries());
        if (!basicDetails1.companyName && !basicDetails1.industryType) {
            getEarlyData();
        }
    }, []);

    const getEarlyData = async () => {
        const earlyIndustry = await getIndustriesService();
        const earlyData = await getEarlyUserService();
        // const industry = earlyIndustry.industries.filter(
        //     (industry) =>
        //         industry.industry_name === earlyData.earlyUser.industryType
        // );
        setData({
            ...data,
            companyName: earlyData?.earlyUser?.companyName,
            industryType: earlyData?.earlyUser?.industryType,
        });
        // setIndustryName(industry[0]?.industry_name);
    };

    const onSubmit = () => {
        const reduxPayload = {
            companyName: data?.companyName,
            maturityLevel: data?.maturityLevel,
            industryType: data?.industryType,
            location: data?.location,
            otherLocation: data?.otherLocation,
            otherIndustry: data?.otherIndustry,
            abn: data?.abn,
            about: data?.about,
        };
        if (!data?.companyName) {
            setNameError(true);
        } else {
            setNameError(false);
        }
        if (!data?.about) {
            setAboutError(true);
        } else {
            setAboutError(false);
        }
        if (!data?.industryType && !suggestionSearch) {
            setIndustryError(true);
        } else {
            setIndustryError(false);
        }

        if (data?.location === "Other" && !data?.otherLocation) {
            setLocationError(true);
        } else {
            setLocationError(false);
        }

        if (!data?.location) {
            return toast({
                title: "Please select Location.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        }
        if (
            data?.companyName &&
            data?.industryType &&
            data.about &&
            data?.location
        ) {
            dispatch(detailsActions.setBasicDetails1(reduxPayload));
            setPage(2);
        }
    };

    return (
        <div>
            <DetailSteps activeStep={activeStep} />
            <div className="container">
                <div className="basic">
                    <div className="basic-left">
                        {/* <button
                            onClick={() => dispatch(getEarlyUsersProfile())}
                        >
                            abcs
                        </button> */}
                        <div className="form">
                            <label htmlFor="about">About the Company*</label>
                            <div>
                                <textarea
                                    name="about"
                                    id="about"
                                    cols="30"
                                    rows="10"
                                    value={data?.about}
                                    onChange={(e) => onChange(e)}
                                    className={aboutError ? "error-input" : ""}
                                    maxLength="550"
                                />
                            </div>
                            <span>
                                {550 - (data?.about ? data?.about.length : 0)}{" "}
                                characters left
                            </span>
                        </div>
                    </div>
                    <div className="basic-right">
                        <div className="company-form">
                            <div className="form-group my-3">
                                <label htmlFor="companyName">
                                    Company name*
                                </label>
                                <input
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    value={data?.companyName}
                                    className={nameError ? "error-input" : ""}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="maturity-level">
                                    Maturity Level*
                                </label>
                                <div className="maturity">
                                    <div className="radinput">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            value="Start up (0-1 years)"
                                            onChange={(e) => onChange(e)}
                                            name="maturityLevel"
                                            checked={
                                                data?.maturityLevel ===
                                                "Start up (0-1 years)"
                                            }
                                        />
                                    </div>
                                    <div className="label-input">
                                        <label className="label-text">
                                            Start up (0-1 years)
                                        </label>
                                    </div>
                                </div>
                                <div className="maturity">
                                    <div className="radinput">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            value="New Business (1-2 years)"
                                            onChange={(e) => onChange(e)}
                                            name="maturityLevel"
                                            checked={
                                                data?.maturityLevel ===
                                                "New Business (1-2 years)"
                                            }
                                        />
                                    </div>
                                    <div className="label-input">
                                        <label className="label-text">
                                            New Business (1-2 years)
                                        </label>
                                    </div>
                                </div>
                                <div className="maturity">
                                    <div className="radinput">
                                        <input
                                            className="radio-input"
                                            type="radio"
                                            value="Mature Business (3+ yrs)"
                                            onChange={(e) => onChange(e)}
                                            name="maturityLevel"
                                            checked={
                                                data?.maturityLevel ===
                                                "Mature Business (3+ yrs)"
                                            }
                                        />
                                    </div>
                                    <div className="label-input">
                                        <label className="label-text">
                                            Mature Business (3+ yrs)
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group my-3 w-100">
                                <label htmlFor="industryType">
                                    Industry Type*
                                </label>
                                <select
                                    name="industryType"
                                    id="industryType"
                                    className={
                                        industryError ? "error-input" : ""
                                    }
                                    onChange={(e) => onChange(e)}
                                    value={data?.industryType}
                                >
                                    {data?.industryType ? (
                                        <option>{data?.industryType}</option>
                                    ) : (
                                        <option value="">
                                            Select Industry
                                        </option>
                                    )}
                                    {industries?.map(
                                        (data) =>
                                            data?.industry_type === 0 && (
                                                <option
                                                    value={data?.industry_name}
                                                >
                                                    {data?.industry_name}
                                                </option>
                                            )
                                    )}
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            {data?.industryType === "Other" && (
                                <div className="form-group my-3 w-100 position-relative">
                                    <label htmlFor="otherIndustry">
                                        Choose Your Industry*
                                    </label>
                                    <input
                                        name="otherIndustry"
                                        autoComplete="on"
                                        className={
                                            data?.industryType === "Other" &&
                                            !data?.otherIndustry
                                                ? "error-input"
                                                : ""
                                        }
                                        placeholder="Start Typing to see Suggestions"
                                        value={data?.otherIndustry}
                                        onChange={onSearchSuggestion}
                                    />
                                    {showSuggestions &&
                                    suggestionSearch?.length ? (
                                        <div className="datalist">
                                            {industries?.map(
                                                (val, ind) =>
                                                    val.industry_name
                                                        .toString()
                                                        .toLocaleLowerCase()
                                                        .includes(
                                                            suggestionSearch.toLocaleLowerCase()
                                                        ) &&
                                                    val.industry_name.toLocaleLowerCase() !==
                                                        suggestionSearch.toLocaleLowerCase() && (
                                                        <p
                                                            key={ind}
                                                            onClick={() =>
                                                                onSuggestionClick(
                                                                    val?.industry_name
                                                                )
                                                            }
                                                        >
                                                            {val.industry_name}
                                                        </p>
                                                    )
                                            )}
                                            <p
                                                onClick={() =>
                                                    onSuggestionClick(
                                                        suggestionSearch
                                                    )
                                                }
                                            >
                                                {suggestionSearch}
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            <div className="form-group my-3">
                                <label htmlFor="location">Location*</label>
                                <div className="location d-flex justify-content-between w-100">
                                    <div className="button">
                                        <label
                                            htmlFor="australia"
                                            className={
                                                data?.location === "Australia"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            Australia
                                        </label>
                                        <input
                                            type="radio"
                                            id="australia"
                                            name="location"
                                            value="Australia"
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                    <div className="button">
                                        <label
                                            htmlFor="NewZealand"
                                            className={
                                                data?.location === "New Zealand"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            New Zealand
                                        </label>
                                        <input
                                            type="radio"
                                            id="NewZealand"
                                            name="location"
                                            value="New Zealand"
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                    <div className="button">
                                        <label
                                            htmlFor="other"
                                            className={
                                                data?.location === "Other"
                                                    ? "label-active"
                                                    : ""
                                            }
                                        >
                                            Other
                                        </label>
                                        <input
                                            type="radio"
                                            id="other"
                                            name="location"
                                            value="Other"
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {data?.location === "Other" && (
                                <div className="form-group my-3">
                                    <label htmlFor="location">Country*</label>
                                    <select
                                        name="otherLocation"
                                        id="otherLocation"
                                        className={
                                            locationError ? "error-input" : ""
                                        }
                                        onChange={(e) => onChange(e)}
                                        value={data?.otherLocation}
                                    >
                                        <option value="">Select Country</option>
                                        {Object.entries(countries).map(
                                            (val) => (
                                                <option value={val[1]}>
                                                    {val[1]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            )}

                            {data?.location && (
                                <div className="form-group my-3">
                                    <label htmlFor="abn">
                                        {data?.location === "Australia"
                                            ? "ABN"
                                            : data?.location === "New Zealand"
                                            ? "NZBN"
                                            : "Local Business Identity Number"}
                                    </label>
                                    <input
                                        type="number"
                                        name="abn"
                                        id="abn"
                                        value={data?.abn}
                                        placeholder="eg. XXXX XXXX"
                                        onChange={(e) => onChange(e)}
                                        // required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-end buttons">
                    <div className="continue-button">
                        <Button
                            className="btn"
                            onClick={onSubmit}
                            color="blue"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetails1;
