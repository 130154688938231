import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-tailwind/react/Button";
import { authActions, getUserProfile } from "../../../data/reducers/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";

import "./ChoosePlan.css";
import DetailSteps from "../../../shared/Details/DetailSteps";
import { detailsActions } from "../../../data/reducers/details/details";

import ComingSoon from "../../../assets/coming-soon.svg";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getSubscriptions } from "../../../data/reducers/company/subscription";
import { useToast } from "@chakra-ui/react";
import { addSocialAction } from "../../../data/reducers/profile/profile";
import moment from "moment";
import { addCompany } from "../../../data/reducers/details/details";
import { startFreeTrail } from "../../../data/api";
import axios from "axios";
import { API_END_POINT } from "../../../config/api";

const ChoosePlan = () => {
    const toast = useToast();
    const [check, setCheck] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const { user } = useSelector((state) => state?.auth);

    const { activeStep } = useSelector((state) => state?.details);
    const { subscriptions } = useSelector((state) => state?.subscription);
    const { basicDetails1, basicDetails2,applyPromoCodeStatus } = useSelector(
        (state) => state?.details
    );

    const data1 = new FormData();
    data1.append("about", basicDetails1?.about);
    data1.append("numberofEmployees", basicDetails2?.numberOfEmployees);
    data1.append("services", basicDetails2?.services);
    data1.append("name", basicDetails1?.companyName);
    data1.append("website", basicDetails2?.companyWebsite);
    data1.append("industryType", basicDetails1?.industryType);
    data1.append("location", basicDetails1?.location);
    data1.append("abn", basicDetails1?.abn);
    data1.append("file", basicDetails2?.logo);
    data1.append("otherLocation", basicDetails1?.otherLocation);
    data1.append("otherIndustry", basicDetails1?.otherIndustry);

    const onFreeTrial = async (type) => {
        dispatch(detailsActions?.setIsFree(true));
        dispatch(detailsActions?.setPlan(type));
        try {
            const res = await startFreeTrail();
            if (res?.status) {
                await dispatch(addCompany(data1));
                if (basicDetails2?.companyWebsite?.length > 1) {
                    const http = "http://";
                    const https = "https://";
                    let companyWebs = "";
                    var value = basicDetails2.companyWebsite;
                    if (value.startsWith(http) || value.startsWith(https)) {
                        companyWebs = basicDetails2.companyWebsite;
                    } else {
                        companyWebs = https.concat(
                            basicDetails2.companyWebsite
                        );
                    }
                    dispatch(
                        addSocialAction({
                            socialName: "Website",
                            socialLink: companyWebs,
                        })
                    );
                }
                await dispatch(getUserProfile());

                dispatch(detailsActions.setIsFree(false));
                dispatch(authActions.setIsAuthenticated(true));

                navigate("/profile");
            } else {
                toast({
                    title: "Error",
                    description: "Something went wrong!",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
            }
        } catch (e) {
            console.log("Free trail error: ", e);
            console.log("Free trail error (BE): ", e?.response);
        }
    };

    const onNext = (type) => {
        dispatch(detailsActions.setActiveStep(3));
        dispatch(detailsActions.setPlan(type));
        navigate(`/details?type=${type}`);
    };
   
    const onBack = () => {
        dispatch(detailsActions.setActiveStep(1));
    };

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);

    return (
        <Fragment>
              {
                user?.Transaction?.is_active ? <div>
                    {moment(user?.subscriptionValidity).diff(moment(), "days") <
                            0 && (
                            <div className="logout">
                                <div
                                    className="logout-Div"
                                    onClick={(e) => {
                                        localStorage.clear();
                                        navigate("/auth");
                                        window.location.reload();
                                    }}
                                >
                                    Logout
                                </div>
                            </div>
                    )}
                    <div className="subs-plan">
                        <h1>Subscription Plans</h1>
                        <p>Simple pricing, no hidden fees, and great value</p>
                    </div>

                    <div className="mt-5 mx-auto col-md-6 col-sm-10 col-12">
                        <div className="text-center">
                            <p className="mx-auto" style={{fontSize:"25px",textAlign:"center"}}>You have already subscribed to {user?.subscription} plan</p>
                            <button className="btn btn-primary" style={{marginTop:"25px"}} onClick={async ()=>{
                                try{
                                     const {data}=await axios.post(`${API_END_POINT}/api/v1/stripe/subscription/schedule`,{id:user?.id,email:user?.email})
                                     console.log(data,">>>>>>>>>>>>> data check next billing")
                                     if(data?.succsess){
                                        navigate("/dashboard") 
                                        return toast({
                                            title: `${data?.message}`,
                                            status: "success",
                                            duration: 3000,
                                            isClosable: true,
                                            position: "top-right",
                                        });
                                     }else{
                                        return toast({
                                            title: `${data?.message}`,
                                            status: "error",
                                            duration: 3000,
                                            isClosable: true,
                                            position: "top-right",
                                        });
                                     }
                                }catch(err){
                                    return toast({
                                        title: `Something went wrong.Please try after sometimes`,
                                        status: "error",
                                        duration: 3000,
                                        isClosable: true,
                                        position: "top-right",
                                    });
                                }
                            }}>Check next billing</button>
                        </div>
                    </div>
                      
                </div>: <>
            <div>
                {moment(user?.subscriptionValidity).diff(moment(), "days") <
                    0 && (
                    <div className="logout">
                        <div
                            className="logout-Div"
                            onClick={(e) => {
                                localStorage.clear();
                                navigate("/auth");
                                window.location.reload();
                            }}
                        >
                            Logout
                        </div>
                    </div>
                )}
                {moment(user?.subscriptionValidity).diff(moment(), "days") <
                0 ? (
                    <div className="subs-plan">
                        <h1>Subscription Plans</h1>
                        <p>Simple pricing, no hidden fees, and great value</p>
                    </div>
                ) : (
                    <>
                        <p
                            className="backbone-para"
                            style={{
                                fontSize: "2rem",
                                textAlign: "center",
                                fontWeight: "bold",
                            }}
                        >
                            BackBone registration
                        </p>
                        <DetailSteps activeStep={activeStep} />
                    </>
                )}
                <div className="container">
                    <div className="payment">
                        {!user?.subscription && (
                            <div className="row mt-3">
                                <div className="subscription my-5">
                                    <h1 className="subs-heading">
                                        Select subscription plan
                                    </h1>
                                    <p
                                        className="details py-3"
                                        style={{
                                            fontSize: "1rem",
                                        }}
                                    >
                                        Simple pricing, no hidden fees, and
                                        great value
                                    </p>
                                </div>
                                {/* <div className="col-lg-8 col-md-10 col sm-10 col-12 mx-auto free-trial">
                                    <div className="w-100">
                                        <p className="free-top">
                                            <span
                                                onClick={() => {
                                                    if (check) {
                                                        onFreeTrial("free");
                                                    } else {
                                                        toast({
                                                            title: "Accept Terms and Condtions",
                                                            description:
                                                                "You need to accept terms and conditions",
                                                            status: "error",
                                                            duration: 3000,
                                                            isClosable: true,
                                                            position:
                                                                "top-right",
                                                        });
                                                    }
                                                }}
                                            >
                                                Click here, &nbsp;
                                            </span>
                                            to get free access for&nbsp;
                                            <strong
                                                style={{
                                                    color: "navy",
                                                }}
                                            >
                                                3 months*
                                            </strong>
                                        </p>
                                    </div>
                                    
                                </div> */}
                            </div>
                        )}

                        {/* Chose a subscription plan add a condition to show plan only after expiry and not while creating for future use  */}

                        <div className="row my-5 plans">
                            {!isMobile ? (
                                <>
                                    {subscriptions?.map((subscription,index) => {
                                        if (
                                            (!user?.subscription &&
                                                user?.requestSent == false) ||
                                            (user?.subscription &&
                                                subscription?.subscription_name !==
                                                    "Free") ||
                                            (user?.requestSent == true &&
                                                subscription?.subscription_name !==
                                                    "Free")
                                        )
                                            return (
                                                <div key={index} className="col-lg-4 col-md-12 col-sm-12 mx-auto">
                                                    <PlanCard
                                                        check={check}
                                                        subscription={
                                                            subscription
                                                        }
                                                        onNext={() => {
                                                            if (
                                                                subscription?.subscription_name ==
                                                                "Free"
                                                            )
                                                                onFreeTrial(
                                                                    "free"
                                                                );
                                                            else
                                                                onNext(
                                                                    subscription?.subscription_name
                                                                );
                                                        }}
                                                    />
                                                </div>
                                            );
                                    })}
                                    {/* <div
                                        className="col-lg-4 col-md-12 col-sm-12 mx-auto plan-card"
                                        style={{
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div> */}
                                </>
                            ) : (
                                <Carousel
                                    showIndicators={true}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    interval={5000}
                                >
                                    
                                    {subscriptions?.map((subscription,index) => {
                                        if (
                                            (!user?.subscription &&
                                                user?.requestSent == false) ||
                                            (user?.subscription &&
                                                subscription?.subscription_name !==
                                                    "Free") ||
                                            (user?.requestSent == true &&
                                                subscription?.subscription_name !==
                                                    "Free")
                                        )
                                            return (
                                                <div key={index}>
                                                    <PlanCard
                                                        check={check}
                                                        subscription={
                                                            subscription
                                                        }
                                                        onNext={() => {
                                                            if (
                                                                subscription?.subscription_name ==
                                                                "Free"
                                                            )
                                                                onFreeTrial(
                                                                    "free"
                                                                );
                                                            else
                                                                onNext(
                                                                    subscription?.subscription_name
                                                                );
                                                        }}
                                                    />
                                                </div>
                                            );
                                    })}
                                    {/* 
                                    <div>
                                        <h1>Platinum</h1>
                                        <div className="coming-soon">
                                            <img
                                                src={ComingSoon}
                                                alt="Platinum"
                                            />
                                        </div>
                                        <div className="coming-soon-text">
                                            <h3 className="text-center">
                                                Coming Soon
                                            </h3>
                                        </div>
                                    </div> */}
                                </Carousel>
                            )}
                        </div>

                        <div
                            style={{
                                paddingBottom: "2rem",
                                justifyContent:
                                    moment(user?.subscriptionValidity).diff(
                                        moment(),
                                        "days"
                                    ) < 0
                                        ? "flex-start"
                                        : "center",
                            }}
                            className="terms-conditions d-flex align-items-center mt-4"
                        >
                            <Checkbox
                                checked={check}
                                color="primary"
                                onChange={(e) => setCheck(e.target.checked)}
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                            <p>
                                I have read and accepted the{" "}
                                <button onClick={() => setShowModal(true)}>
                                    <span>Terms and Conditions</span>
                                </button>{" "}
                                of use
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        height:
                            moment(user?.subscriptionValidity).diff(
                                moment(),
                                "days"
                            ) < 0
                                ? "0px"
                                : "20vh",
                    }}
                />
                <div className="d-flex w-100 justify-content-between buttons">
                    <div className="back-button">
                        <Button
                            onClick={onBack}
                            color="blue"
                            buttonType="filled"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            <div className="tnc-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <h1 className="tnc-modal-head">Terms and Conditions</h1>
                        <div className="tnc-modal-body">
                            <p>
                                <b>Welcome to Backbone</b> !<br />
                                To make it easier for you to understand the
                                terms on which we provide our Platform, we've
                                tried to keep these terms of use (Terms) as
                                simple as possible by using plain English.
                                <br />
                                These terms apply to our web application which
                                is a platform which allows businesses to
                                advertise and gain endorsements for their
                                products and services and is further described
                                on our website accessible at www.bizbackbone.com
                                (Platform). We've set out these Terms to govern
                                your subscription to the Platform
                                (Subscription).
                                <br />
                                When we talk about “Backbone”, “we,” “our,” or
                                “us” in these Terms, we are referring to
                                Backbone Services Pty Ltd ABN 33 656 180 504.
                                <br />
                                We've also used a few other capitalised words
                                and phrases as shorthand to refer to recurring
                                concepts. Each of these are defined in bold and
                                in brackets after the concepts are first
                                mentioned.
                                <br />
                                By clicking the tick box on the Platform, paying
                                for your Subscription, signing up for a Trial or
                                otherwise accepting the benefit of any part of
                                the Platform, you agree to be bound by these
                                Terms which form a binding contractual agreement
                                between you or the company you represent (you)
                                and Backbone. You represent and warrant that you
                                have valid authority to enter into these Terms
                                on behalf of any entity you may represent.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    1 &emsp;INTRODUCTION
                                </span>
                                <br />
                                <br />
                                These Terms set out the terms and conditions
                                that apply when you use the Platform. By using
                                the Platform or otherwise engaging with the
                                content on the Platform, you represent and
                                warrant that you:
                                <br />
                                (a)&emsp; are at least 18 years old, have the
                                legal capacity to enter into a binding legal
                                agreement with us and you agree to be bound by
                                these Terms; and
                                <br />
                                (b)&emsp; operate a valid business in the
                                jurisdiction you are operating in and have the
                                relevant business ID number for the
                                jurisdiction.
                                <br />
                                Please have a careful read through these Terms
                                before using the Platform. If you don't agree to
                                these Terms, please do not continue to use the
                                Platform.
                                <br />
                                We may modify our Terms from time to time. If we
                                do, we will post a note on this page or email
                                you. If you continue to use the Platform after
                                we modify our Terms, you'll be taken to have
                                agreed to the Terms as modified.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    2 &emsp;DISCLAIMER
                                </span>
                                <br />
                                <br />
                                By accessing the Platform, you acknowledge and
                                agree that:
                                <br />
                                (a)&emsp; Backbone is a forum for businesses to
                                interact with each other and provides
                                advertising space to these businesses and to the
                                extent permitted by law, disclaims all
                                warranties in relation to any posts by a
                                Backbone Account user about the products or
                                services they advertised on the Platform
                                <br />
                                (b)&emsp; Backbone does not represent that any
                                endorsements or advertisements on the Platform
                                will be accurate or guarantee any results;
                                <br />
                                (c)&emsp; you will make your own enquiries as to
                                the nature and reliability of any products,
                                services or businesses advertised on the
                                Platform;
                                <br />
                                (d)&emsp; Backbone does not endorse or evaluate
                                any of the advertised products, services and
                                claims posted on the Platform; and
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    3&emsp; WAITLIST AND EARLY ACCESS
                                </span>
                                <br />
                                <br />
                                (a)&emsp; You may register for early access to
                                the Platform by completing the sign-up form
                                available on the Platform (Waitlist).
                                <br />
                                (b)&emsp; By joining the Waitlist, you
                                acknowledge and agree that the information you
                                provide during the Waitlist registration process
                                may be used by Backbone to complete the
                                registration of your Backbone Account.
                                <br />
                                (c)&emsp; By joining the Waitlist, you are not
                                guaranteed early access to the Platform. You
                                acknowledge and agree that your early access to
                                the Platform will be granted at the sole and
                                absolute discretion of Backbone.
                                <br />
                                (d)&emsp; Backbone may provide you with free
                                access to the Platform and credit towards your
                                use of the Platform at times and amounts
                                determined by Backbone (Early Access Benefits).
                                <br />
                                (e)&emsp; You acknowledge and agree that
                                Backbone is not obliged to provide you with any
                                Early Access Benefits. <br />
                                <br />
                                <span className="terms-subheader">
                                    Optional
                                </span>
                                : We may use Google Analytics to collect and
                                process data, including when you use third party
                                websites or apps. To find out more see How
                                Google uses data when you use our partners'
                                sites or apps.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    4&emsp; ACCOUNT REGISTRATION
                                </span>
                                <br />
                                <br />
                                4.1&emsp; GENERAL
                                <br />
                                <br />
                                In order to use the Platform, you will be
                                required to sign up for an account (Backbone
                                Account). When you register for a Backbone
                                Account, you must provide true, accurate and
                                complete information as requested and keep this
                                information up to date after registration. You
                                agree that you're solely responsible for:
                                <br />
                                (a)&emsp; maintaining the confidentiality and
                                security of your Backbone Account information
                                and your password; and
                                <br />
                                (b)&emsp; any activities and those of any third
                                party that occur through your Backbone Account,
                                whether those activities have been authorised by
                                you or not.
                                <br />
                                You also agree to let us know if you detect any
                                unusual activity on your account as soon as you
                                become aware of it.
                                <br />
                                We won't be responsible to you for, and
                                expressly disclaim any liability for, any cost,
                                loss, damages or expenses arising out of a
                                failure by you to maintain the security of your
                                Backbone Account information or your password.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    5&emsp; VERIFICATION
                                </span>
                                <br />
                                <br />
                                (a)&emsp; (Verification) We may offer or require
                                you to verify your details (including, business,
                                company and credit card details) using our
                                processes or an external identity verification
                                service as applicable (Verification Service).
                                <br />
                                (b)&emsp; (Your personal information and
                                privacy) We will collect your personal
                                information in accordance with our Privacy
                                Policy as set out in clause 20. Where a
                                Verification Service is used, you acknowledge
                                and agree that:
                                <br />
                                &emsp;(i)&emsp; we may contact and share your
                                personal information with a Verification Service
                                to verify your details;
                                <br />
                                &emsp;(ii)&emsp; you consent to us receiving,
                                sharing and using this information to enable us
                                to carry out the Verification Service.
                                <br />
                                (c)&emsp; (Fees) We may charge non-refundable
                                fees for the Verification Service, as set out on
                                the Platform.
                                <br />
                                (d)&emsp; (Warranty and Indemnity) You
                                acknowledge and agree that:
                                <br />
                                &emsp;(i)&emsp; we are reliant on the
                                information provided by the Verification Service
                                to verify your identity and to the extent
                                permitted by law, we disclaim all warranties
                                that <br />
                                <br />
                                <span className="terms-subheader">
                                    6&emsp; FEES
                                </span>
                                <br />
                                <br />
                                6.1&emsp; TRIAL
                                <br />
                                <br />
                                Our website may contain links to other websites.
                                Those links are provided for convenience and may
                                not remain current or be maintained. We are not
                                responsible for the privacy practices of those
                                linked websites and we suggest you review the
                                privacy policies of those websites before using
                                them.
                                <br />
                                <br />
                                6.2&emsp; SUBSCRIPTION
                                <br />
                                <br />
                                (a)&emsp; Subject to 6.1, access to the Platform
                                is available via a paid subscription
                                (Subscription).We may have different
                                subscription periods (Subscription Period).
                                Details of Subscriptions as well as the
                                associated fee for each (Subscription Fees) will
                                be set out on the Platform.
                                <br />
                                (b)&emsp; The Subscription Fees may be paid via
                                the Platform or as otherwise notified by us to
                                you, depending on our current prices and billing
                                processes.
                                <br />
                                (c)&emsp; Once you subscribe to the Platform,
                                your Subscription will continue to renew
                                automatically at the end of each Subscription
                                Period, and you will continue to be charged
                                Subscription Fees unless you cancel your
                                Subscription in accordance with clause 19.
                                <br />
                                <br />
                                6.3&emsp; SUBSCRIPTION FEES AND PAYMENT
                                <br />
                                <br />
                                (a)&emsp; (When Subscription Fees are due) You
                                must pay the Subscription Fee at the beginning
                                of each Subscription Period. You may choose your
                                preferred Subscription Period via the Platform.
                                <br />
                                (b)&emsp; (Automatic recurring billing) Your
                                Subscription will continue to renew after the
                                end of each Subscription Period, indefinitely,
                                and you must pay Subscription Fees in respect of
                                each Subscription Period in advance, unless you
                                notify us before the end of the then current
                                Subscription Period that you want to cancel your
                                Subscription. Otherwise, we will continue to
                                automatically charge the Subscription Fees from
                                your nominated account at the beginning of each
                                Subscription Period. We will not pay any charge
                                back amount if you fail to cancel your
                                Subscription. You acknowledge that your
                                Subscription has an initial and recurring
                                payment feature and you accept responsibility
                                for all recurring charges prior to your
                                cancellation of your Subscription.
                                <br />
                                (c)&emsp; (Failure to pay) If Subscription Fees
                                are not paid when they are due, we may suspend
                                your Backbone Account for 1 month (Suspension
                                Period). If you do no pay the outstanding
                                Subscription Fees within the Suspension Period,
                                we will cancel your Backbone Account and all
                                information and remove all endorsements
                                associated with your Backbone Account.
                                <br />
                                (d)&emsp; (Third Party Payment Provider) We use
                                third-party payment providers (Payment Provider)
                                to collect Subscription Fees. The processing of
                                payments by the Payment Provider will be, in
                                addition to these terms, subject to the terms,
                                conditions and privacy policies of the Payment
                                Provider and we are not liable for the security
                                or performance of the Payment Provider. We
                                reserve the right to correct, or to instruct our
                                Payment Provider to correct, any errors or
                                mistakes in collecting your payment.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    7&emsp; REFERRAL FEES
                                </span>
                                <br />
                                <br />
                                (a)&emsp; Backbone may, in its absolute
                                discretion, provide users with referral benefits
                                (Referral Benefits) if this clause 7 is met
                                (Referral Program). A Referral Benefit may take
                                the form of bizbucks or Subscription
                                credit or any other benefit and will not take
                                the form of any currency or money. A Referral
                                Benefit will only be payable to you if you refer
                                to us a new user (New User) who has not
                                previously registered a Backbone Account.
                                <br />
                                (b)&emsp; The New User must:
                                <br />
                                &emsp;(i)&emsp; not be a member of the Platform;
                                and
                                <br />
                                &emsp;(ii)&emsp; sign up for a Subscription.
                                <br />
                                (c)&emsp; Backbone may withdraw, vary, suspend
                                or cancel any part of the Referral Program at
                                any time in its absolute discretion.
                                <br />
                                (d)&emsp; If your Referral signs up to the
                                Platform, Backbone may provide you with a
                                Referral Benefit as communicated to you by
                                Backbone.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    8&emsp; PROMOTIONS
                                </span>
                                <br />
                                <br />
                                Backbone may run one or more promotions,
                                including but not limited to those referred to
                                in these Terms such as Trials, Early Access
                                Benefits and Referral Benefits (Promotions).
                                Additional details on Backbone's current
                                Promotions will be set out on Backbone's website
                                accessible here www.bizbackbone.com.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    9&emsp; ADVERTISING SPACE
                                </span>
                                <br />
                                <br />
                                (a)&emsp; You may pay for advertising space on
                                the Platform using the functionality provided on
                                the Platform (Advertising Space).
                                <br />
                                (b)&emsp; Backbone offers the following types of
                                advertising:
                                <br />
                                &emsp;(i)&emsp; cost per click
                                <br />
                                &emsp;(ii)&emsp; cost per impression; or
                                <br />
                                &emsp;(iii)&emsp; targeted ads
                                <br />
                                together referred to as “Ads”
                                <br />
                                (c)&emsp; Information regarding the payment
                                plans for the Ads is available on the Platform.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    10&emsp; PARTNERSHIPS
                                </span>
                                <br />
                                <br />
                                (a)&emsp; As a part of your Subscription, we may
                                introduce you to other businesses which may
                                offer you discounted rates or other benefits in
                                relation to their services (Partners).
                                <br />
                                (b)&emsp; By engaging a Partner's services, you
                                acknowledge and agree that Backbone's
                                participation in this relationship is
                                introductory in nature, and we have no liability
                                for any acts or omissions of the Partner.
                                <br />
                                (c)&emsp; To the maximum extent permitted by
                                law, we disclaim all warranties regarding the
                                Partner's provision of services and you should
                                make your own inquiries as to the nature of the
                                Partner's services before engaging them.
                                <br />
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    11&emsp; REFUNDS
                                </span>
                                <br />
                                <br />
                                Except as otherwise set out on our Platform, we
                                don't offer refunds for any of our subscriptions
                                and any refunds we issue will be solely at our
                                discretion.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    12&emsp; ACCEPTABLE USE
                                </span>
                                <br />
                                <br />
                                (a)&emsp; We'll need you to make a few promises
                                about the way you'll use the Platform.
                                <br />
                                (b)&emsp; You agree:
                                <br />
                                &emsp;(i)&emsp; not to copy, reproduce,
                                translate, adapt, vary or modify the Platform
                                without our express consent;
                                <br />
                                &emsp;(ii)&emsp; not to use the Platform in a
                                manner that is illegal, fraudulent, sexually
                                explicit, politically or religiously
                                inappropriate or facilitates illegal or
                                fraudulent activity;
                                <br />
                                &emsp;(iii)&emsp; not to use the Service for the
                                purpose of distributing unsolicited commercial
                                content, junk mail, spam, bulk content or
                                harassment;
                                <br />
                                &emsp;(iv)&emsp; not to attempt to breach the
                                security of the Platform or Backbone's system
                                security, or otherwise interfere with the normal
                                function of the Platform, including by:
                                <br />
                                &emsp;&emsp;(A)&emsp; gaining unauthorised
                                access to Backbone Accounts or data about other
                                users of the Platform;
                                <br />
                                &emsp;&emsp;(B)&emsp; scanning, probing or
                                testing the Platform for security
                                vulnerabilities;
                                <br />
                                &emsp;&emsp;(C)&emsp; overload, flood, mailbomb,
                                crash or submit a virus to the Platform or
                                Backbone's system; or
                                <br />
                                &emsp;&emsp;(D)&emsp; instigate or participate
                                in a denial-of-service attack against the
                                Platform or Backbone's system; and
                                <br />
                                (c)&emsp; to ensure that your employees,
                                sub-contractors and other agents who you have
                                authorised to use or access the Platform comply
                                with the Terms.
                                <br />
                                (d)&emsp; If you breach or are suspected to have
                                breached any of your obligations in this clause
                                12, Backbone reserves the right to suspend or
                                cancel your Subscription without notice or any
                                refunds.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    13&emsp; YOUR CONTENT
                                </span>
                                <br />
                                <br />
                                13.1&emsp; TYPES OF CONTENT
                                <br />
                                <br />
                                As part of using the Platform, you'll be
                                uploading images, content, information and
                                materials you share with us or the public
                                (including feedback, suggestions and enhancement
                                requests), including by using the features of
                                the Platform, sharing content via the Platform
                                on social media or by contacting us, or when you
                                register a Backbone Account (Posted Materials).
                                <br />
                                <br />
                                13.2&emsp; POSTED MATERIALS
                                <br />
                                <br />
                                By providing or posting any Posted Materials,
                                you represent and warrant that: (a)&emsp; you
                                are authorised to provide the Posted Materials;
                                <br />
                                (b)&emsp; the Posted Materials are free from any
                                harmful, discriminatory, sexually explicit,
                                politically or religiously inappropriate,
                                defamatory or maliciously false implications and
                                do not contain any offensive or explicit
                                material;
                                <br />
                                (c)&emsp; the Posted Materials are not “passing
                                off” of any product or service and does not
                                constitute unfair competition;
                                <br />
                                (d)&emsp; the Posted Materials do not infringe
                                any and all present and future intellectual and
                                industrial property rights throughout the world
                                (whether registered or unregistered), including
                                copyright, trade marks, designs, patents, moral
                                rights, semiconductor and circuit layout rights,
                                trade, business, company and domain names, and
                                other proprietary rights, trade secrets,
                                know-how, technical data, confidential
                                information and the right to have information
                                kept confidential, or any rights to registration
                                of such rights (including renewal), whether
                                created before or after the date of this
                                agreement (Intellectual Property Rights);
                                <br />
                                (e)&emsp; the Posted Materials are accurate and
                                true at the time they are provided;
                                <br />
                                (f)&emsp; any Posted Materials which are in the
                                form of a review or feedback is honest, accurate
                                and presents a fair view of the relevant company
                                and/or your experience;
                                <br />
                                (g)&emsp; the Posted Materials do not contain
                                any viruses or other harmful code, or otherwise
                                compromise the security or integrity of any
                                network or system; and
                                <br />
                                (h)&emsp; the Posted Materials do not breach or
                                infringe any applicable laws, regulations or
                                orders.
                                <br />
                                <br />
                                13.&emsp; POSTED MATERIALS - IP LICENCE
                                <br />
                                <br />
                                By uploading any Posted Materials, you grant to
                                Backbone (and its agents or service providers) a
                                perpetual, irrevocable, transferable, worldwide
                                and royalty-free licence (including the right to
                                sublicense) to use, copy, modify, reproduce and
                                adapt any Intellectual Property Rights in that
                                Posted Material in order for Backbone to use,
                                exploit or otherwise enjoy the benefit of such
                                Posted Material.
                                <br />
                                <br />
                                13.4&emsp; REMOVAL OF POSTED MATERIALS
                                <br />
                                <br />
                                We don't have any obligations to screen Posted
                                Materials in advance of them being posted and
                                your compliance with these Terms is your
                                responsibility. However, we may, if we choose,
                                review and remove any Posted Materials at any
                                time without giving any explanation or
                                justification for removing the material and/or
                                information.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    14&emsp; OUR CONTENT
                                </span>
                                <br />
                                <br />
                                Unless we indicate otherwise, all materials used
                                in the Platform (including text, graphics,
                                logos, icons, sound recordings and software) are
                                subject to Intellectual Property Rights that are
                                owned or licensed by us.
                                <br />
                                You can only access and use these materials for
                                the sole purpose of enabling you to use the
                                Platform in accordance with the plan you are on,
                                except to the extent permitted by law or where
                                you have received prior written approval from
                                us.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    15&emsp; THIRD PARTY CONTENT & LINKS
                                </span>
                                <br />
                                <br />
                                The Platform may contain text, images, data and
                                other content provided by a third party (Third
                                Party Content). We're not responsible for any of
                                this Third Party Content and we make no
                                representation or warranty about the quality,
                                suitability, accuracy, reliability, currency or
                                completeness of any Third Party Content.
                                <br />
                                The Platform may also contain links to websites
                                operated by third parties (Third Party Links).
                                Third Party Links are provided for convenience
                                and may not remain current or be maintained. We
                                do not endorse and are not responsible for Third
                                Party Links and have no control over or rights
                                in linked websites.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    16&emsp; SERVICE LIMITATIONS
                                </span>
                                <br />
                                <br />
                                The Platform are made available to you strictly
                                on an ‘as is' basis. We can't guarantee, and
                                make no warranties, to the extent permitted by
                                law, that:
                                <br />
                                (a)&emsp; the Platform will be free from errors
                                or defects;
                                <br />
                                (b)&emsp; the Platform will be accessible or
                                available at all times;
                                <br />
                                (c)&emsp; messages sent through the Platform
                                will be delivered promptly, or delivered at all;
                                <br />
                                (d)&emsp; information you receive or supply
                                through the Platform will be secure or
                                confidential; or
                                <br />
                                (e)&emsp; any information provided through the
                                Platform is accurate or true.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    18&emsp; LIABILITY AND INDEMNITY
                                </span>
                                <br />
                                <br />
                                To the maximum extent permitted by applicable
                                law, Backbone limits all liability in aggregate
                                to any person for loss or damage of any kind,
                                however arising whether in contract, tort
                                (including negligence), statute, equity,
                                indemnity or otherwise, arising from or relating
                                in any way to the Platform to the total amount
                                of fees paid by you in the 3 months preceding
                                the event which gives rise to the liability.
                                This includes the transmission of any computer
                                virus.
                                <br />
                                You agree to indemnify Backbone and its
                                employees, contractors and agents in respect of
                                all liability for loss, damage or injury which
                                may be suffered by any person arising from, or
                                in connection with, you or your representatives
                                use of the Platform and/or breach of these
                                Terms. All express or implied representations
                                and warranties given by us are, to the maximum
                                extent permitted by applicable law, excluded.
                                <br />
                                Nothing in this agreement is intended to limit
                                the operation of the Australian Consumer Law
                                contained in the Competition and Consumer Act
                                2010 (Cth) (ACL). Under the ACL, you may be
                                entitled to certain remedies (like a refund) if
                                there is a failure with the Platform.
                                <br />
                                To the maximum extent permitted by law, under no
                                circumstances will Backbone be liable for any
                                incidental, special or consequential loss or
                                damages, or damages for loss of data, business
                                or business opportunity, goodwill, anticipated
                                savings, profits or revenue arising under or in
                                connection with the Platform, these Terms or
                                their subject matter (except to the extent this
                                liability cannot be excluded under the
                                Competition and Consumer Act 2010 (Cth)).
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    19&emsp; CANCELLATION
                                </span>
                                <br />
                                <br />
                                19.1&emsp; CANCELLATION BY YOU
                                <br />
                                <br />
                                You are responsible for the cancellation of your
                                Backbone Account. You can cancel your Backbone
                                Account at any time by using the functionality
                                provided on the Platform.
                                <br />
                                <br />
                                19.2&emsp; CANCELLATION BY US
                                <br />
                                <br />
                                To the maximum extent permitted by law, we
                                reserve the right to cancel your access to part
                                or all of the Platform at any time, effective
                                immediately, and you will not be refunded unless
                                certain conditions are met, which are to be
                                determined at Backbone's complete discretion.
                                <br />
                                <br />
                                19.3&emsp; EFFECT OF CANCELLATION
                                <br />
                                <br />
                                (a)&emsp; Upon cancellation, termination or
                                expiry of your Backbone Account, we will delete
                                any Posted Materials, advertisements and
                                endorsements associated with your Backbone
                                Account. You won't be able to recover any of
                                this after cancellation, termination or expiry
                                of your Backbone Account so we recommend you
                                back up anything important to you.
                                <br />
                                (b)&emsp; Backbone won't be responsible to you
                                for, and expressly disclaim any liability for,
                                any cost, loss, damages or expenses arising out
                                the cancellation, termination or expiry of your
                                Backbone Account.
                                <br />
                                (c)&emsp; You acknowledge and agree that if you
                                cancel your account in accordance with this
                                clause 19, you will not be entitled to any
                                refunds for the remaining Subscription Period.
                                <br />
                                <br />
                                19.4&emsp; SURVIVAL
                                <br />
                                <br />
                                Any clause that by its nature would reasonably
                                be expected to be performed after the
                                termination or expiry of this agreement will
                                survive and be enforceable after such
                                termination or expiry.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    20&emsp; PRIVACY AND COOKIE POLICY
                                </span>
                                <br />
                                <br />
                                You agree to be bound by the clauses outlined in
                                Backbone's Privacy Policy (accessible on
                                www.bizbackbone.com) and Cookie Policy
                                (accessible on www.bizbackbone.com)
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    21&emsp; LOCATION OF PLATFORM
                                </span>
                                <br />
                                <br />
                                Backbone controls the operation of the Platform
                                from headquarters located in Australia. Some
                                Platform or parts thereof may be operated from,
                                or hosted on mirrors or servers, at various
                                locations outside of Australia.
                                <br />
                                We make no representation or warranty that all
                                of the features of the Platform will be
                                available to you outside of Australia or that
                                they are permitted to be accessed outside
                                Australia.
                                <br />
                                You're solely responsible for your decision to
                                use the Platform from other locations and you
                                acknowledge that such use may be subject to, and
                                you are responsible for, compliance with
                                applicable local laws in relation to your use of
                                the Platform.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    22&emsp; NOTICES
                                </span>
                                <br />
                                <br />
                                (a)&emsp; A notice or other communication to a
                                party under this agreement must be:
                                <br />
                                &emsp;(i)&emsp; in writing and in English; and
                                <br />
                                &emsp;(ii)&emsp; delivered via email to the
                                other party, to the email address specified in
                                this agreement, or if no email address is
                                specified in this agreement, then the email
                                address most regularly used by the parties to
                                correspond regarding the subject matter of this
                                agreement as at the date of this agreement
                                (Email Address). The parties may update their
                                Email Address by notice to the other party.
                                <br />
                                (b)&emsp; Unless the party sending the notice
                                knows or reasonably ought to suspect that an
                                email was not delivered to the other party's
                                Email Address, notice will be taken to be given:
                                <br />
                                &emsp;(i)&emsp; 24 hours after the email was
                                sent, unless that falls on a Saturday, Sunday or
                                a public holiday in the state or territory whose
                                laws govern this agreement, in which case the
                                notice will be taken to be given on the next
                                occurring business day in that state, unless
                                that falls on a Saturday, Sunday or a public
                                holiday in the state or territory whose laws
                                govern this agreement, in which case the notice
                                will be taken to be given on the next occurring
                                business day in that state or territory; or
                                <br />
                                &emsp;(ii)&emsp; when replied to by the other
                                party, whichever is earlier.
                                <br />
                                <br />
                                <span className="terms-subheader">
                                    23&emsp; GENERAL
                                </span>
                                <br />
                                <br />
                                23.1&emsp; GOVERNING LAW AND JURISDICTION
                                <br />
                                <br />
                                This agreement is governed by the law applying
                                in New South Wales, Australia. Each party
                                irrevocably submits to the exclusive
                                jurisdiction of the courts of New South Wales,
                                Australia and courts of appeal from them in
                                respect of any proceedings arising out of or in
                                connection with this agreement. Each party
                                irrevocably waives any objection to the venue of
                                any legal process on the basis that the process
                                has been brought in an inconvenient forum.
                                <br />
                                <br />
                                23.2&emsp; WAIVER
                                <br />
                                <br />
                                No party to this agreement may rely on the words
                                or conduct of any other party as a waiver of any
                                right unless the waiver is in writing and signed
                                by the party granting the waiver.
                                <br />
                                <br />
                                23.3&emsp; SEVERANCE
                                <br />
                                <br />
                                Any term of this agreement which is wholly or
                                partially void or unenforceable is severed to
                                the extent that it is void or unenforceable. The
                                validity and enforceability of the remainder of
                                this agreement is not limited or otherwise
                                affected.
                                <br />
                                <br />
                                23.4&emsp; JOINT AND SEVERAL LIABILITY
                                <br />
                                <br />
                                An obligation or a liability assumed by, or a
                                right conferred on, two or more persons binds or
                                benefits them jointly and severally.
                                <br />
                                <br />
                                23.5&emsp; ASSIGNMENT
                                <br />
                                <br />
                                A party cannot assign, novate or otherwise
                                transfer any of its rights or obligations under
                                this agreement without the prior written consent
                                of the other party.
                                <br />
                                <br />
                                23.6&emsp; COSTS
                                <br />
                                <br />
                                Except as otherwise provided in this agreement,
                                each party must pay its own costs and expenses
                                in connection with negotiating, preparing,
                                executing and performing this agreement.
                                <br />
                                <br />
                                23.7&emsp; ENTIRE AGREEMENT
                                <br />
                                <br />
                                This agreement embodies the entire agreement
                                between the parties and supersedes any prior
                                negotiation, conduct, arrangement, understanding
                                or agreement, express or implied, in relation to
                                the subject matter of this agreement.
                                <br />
                                <br />
                                23.8&emsp; INTERPRETATION
                                <br />
                                <br />
                                (a)&emsp; (singular and plural) words in the
                                singular includes the plural (and vice versa);
                                <br />
                                (b)&emsp; (currency) a reference to $; or
                                “dollar” is to Australian currency;
                                <br />
                                (c)&emsp; (gender) words indicating a gender
                                includes the corresponding words of any other
                                gender;
                                <br />
                                (d)&emsp; (defined terms) if a word or phrase is
                                given a defined meaning, any other part of
                                speech or grammatical form of that word or
                                phrase has a corresponding meaning;
                                <br />
                                (e)&emsp; (person) a reference to “person” or
                                “you” includes an individual, the estate of an
                                individual, a corporation, an authority, an
                                association, consortium or joint venture
                                (whether incorporated or unincorporated), a
                                partnership, a trust and any other entity;
                                <br />
                                (f)&emsp; (party) a reference to a party
                                includes that party's executors, administrators,
                                successors and permitted assigns, including
                                persons taking by way of novation and, in the
                                case of a trustee, includes any substituted or
                                additional trustee;
                                <br />
                                (g)&emsp; (this agreement) a reference to a
                                party, clause, paragraph, schedule, exhibit,
                                attachment or annexure is a reference to a
                                party, clause, paragraph, schedule, exhibit,
                                attachment or annexure to or of this agreement,
                                and a reference to this agreement includes all
                                schedules, exhibits, attachments and annexures
                                to it;
                                <br />
                                (h) &emsp;(document) a reference to a document
                                (including this agreement) is to that document
                                as varied, novated, ratified or replaced from
                                time to time;
                                <br />
                                (i)&emsp; (headings) headings and words in bold
                                type are for convenience only and do not affect
                                interpretation;
                                <br />
                                (j)&emsp; (includes) the word “includes” and
                                similar words in any form is not a word of
                                limitation; and
                                <br />
                                (k)&emsp; (adverse interpretation) no provision
                                of this agreement will be interpreted adversely
                                to a party because that party was responsible
                                for the preparation of this agreement or that
                                provision.
                                <br />
                                <br />
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
              }
        </Fragment>
       
    );
};

const PlanCard = ({ subscription, onNext, check }) => {
    const toast = useToast();
    var desc = subscription?.subscription_description.split(",");
    return (
        <>
            <div className="plan-card mx-auto">
                {subscription && (
                    <div>
                        <h1>{subscription?.subscription_name}</h1>
                        <h2 className="d-flex justify-content-center align-items-center">${subscription?.subscription_price} {subscription?.subscription_name!="Free" && <span className="text-secondary fs-6 fw-bold ms-2">+ GST</span>}</h2>
                        <div className="features py-4">
                            <div>
                                {desc.map((val, ind) => (
                                    <div key={ind}>
                                        <div
                                            style={{
                                                color: "#646464",
                                            }}
                                        >
                                            • {val}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="select-plan-button">
                            <Button
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={() => {
                                    if (check) {
                                        onNext();
                                    } else {
                                        toast({
                                            title: "Accept Terms and Condtions",
                                            description:
                                                "You need to accept terms and conditions",
                                            status: "error",
                                            duration: 3000,
                                            isClosable: true,
                                            position: "top-right",
                                        });
                                    }
                                }}
                            >
                                Select Plan
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ChoosePlan;
