import React, { useState, useEffect } from "react";
import { toast, useToast } from "@chakra-ui/react";
import { Button } from "@material-tailwind/react";
import { BiLock } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    addCompany,
    detailsActions,
    makePayment,
    savePayment,
} from "../../../data/reducers/details/details";

import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { AiOutlineCreditCard, AiOutlineIdcard } from "react-icons/ai";
import { BsCalendarEvent } from "react-icons/bs";
import { MdVpnKey } from "react-icons/md";

import DetailSteps from "../../../shared/Details/DetailSteps";

import "./Payment.css";
import { authActions, getUserProfile } from "../../../data/reducers/auth/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { addSocialAction } from "../../../data/reducers/profile/profile";
import moment from "moment";
import { setCost } from "../../../data/reducers/payment/payment";
import {
    applyPromoCodeAPI,
    makePaymentAPIWithCredits,
} from "../../../data/api";

const Payment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { basicDetails1, basicDetails2, isFree, activeStep, type } =
        useSelector((state) => state.details);

    const data1 = new FormData();
    data1.append("about", basicDetails1.about);
    data1.append("numberofEmployees", basicDetails2.numberOfEmployees);
    data1.append("services", basicDetails2.services);
    data1.append("name", basicDetails1.companyName);
    data1.append("website", basicDetails2.companyWebsite);
    data1.append("industryType", basicDetails1.industryType);
    data1.append("location", basicDetails1.location);
    data1.append("abn", basicDetails1.abn);
    data1.append("file", basicDetails2.logo);
    data1.append("otherLocation", basicDetails1.otherLocation);
    data1.append("otherIndustry", basicDetails1.otherIndustry);

    const [check, setCheck] = useState();

    const onBack = () => {
        dispatch(detailsActions.setActiveStep(2));
        dispatch(detailsActions.setIsFree(false));
    };

    const onNext = async () => {
        await dispatch(getUserProfile());
        dispatch(detailsActions.setIsFree(false));
        dispatch(authActions.setIsAuthenticated(true));
        navigate("/paymentsuccess");
    };

    return (
        <div className="payments">
            <p
                className="backbone-para"
                style={{
                    fontSize: "2rem",
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                BackBone registration
            </p>
            <DetailSteps activeStep={activeStep} />
            <div className="heading mt-4">
                <h1>{isFree ? "Enter your card details" : "Payment"}</h1>
                <h5>
                    <BiLock color="#007893" /> &nbsp;
                    {isFree
                        ? "Secure your card details with us"
                        : "Simple and secure payment"}
                </h5>
            </div>
            <div className="payments-card">
                {isFree ? (
                    <FreeTrial
                        check={check}
                        setCheck={setCheck}
                        onNext={onNext}
                        onBack={onBack}
                        data={data1}
                        type={type}
                    />
                ) : (
                    <PlanPayment
                        check={check}
                        setCheck={setCheck}
                        onNext={onNext}
                        onBack={onBack}
                        data={data1}
                        type={type}
                    />
                )}
            </div>
        </div>
    );
};

const PlanPayment = ({ check, setCheck, onNext, onBack, data, type }) => {
    const toast = useToast();
    const location = useLocation();
    const reactive = location.state?.deactivate;
    const navigate = useNavigate();
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const [passed, setPassed] = useState(false);
    const [selectedSub, setSelectedSub] = useState({});
    const { isFree } = useSelector((state) => state?.details);
    const { user } = useSelector((state) => state?.auth);
    const { basicDetails2 } = useSelector((state) => state.details);
    const [discount, setDiscount] = useState(0);
    const { subscriptions } = useSelector((state) => state.subscription);
    const [loading, setLoading] = useState(false);
    const [promo, setpromo] = useState("");
    const [promoError, setpromoError] = useState("");
    const [promoApplied, setPromoApplied] = useState(false);
    const submitHandler = async () => {
        if (
            selectedSub?.subscription_name !== "Annual" ||
            selectedSub?.subscription_cost - discount !== 0
        ) {
            if (!passed) {
                toast({
                    title: "Error",
                    description: "Enter card details",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
                return;
            }
            console.log("adsd", stripe, elements.getElement(CardNumberElement));
            setLoading(true);
            if (!stripe || !elements) {
                return;
            }

            const result = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: `${user?.firstName} ${user?.lastName}`,
                    email: `${user?.email}`,
                },
            });

            const { payload } = await dispatch(
                makePayment({
                    payment_method: result?.paymentMethod?.id,
                    email: user?.email,
                    type: type,
                    reactive,
                    applyCredit:
                        selectedSub?.subscription_name !== "Annual"
                            ? false
                            : promoApplied,
                })
            );
            const { client_secret } = payload;
            console.log("payload", payload);
            if (
                payload?.success &&
                (payload?.trial_going_on || payload?.appliedCredit)
            ) {
                // await dispatch(addCompany(data));
                // await dispatch(getUserProfile());
                // navigate("/paymentsuccess");
                // await dispatch(getUserProfile());
                // navigate("/profile");

                dispatch(
                    setCost({
                        cost: selectedSub?.subscription_cost - discount,
                    })
                );
                navigate("/paymentsuccess");
                if (basicDetails2.companyWebsite?.length > 1) {
                    const http = "http://";
                    const https = "https://";
                    let companyWebs = "";
                    var value = basicDetails2.companyWebsite;
                    if (value.startsWith(http) || value.startsWith(https)) {
                        companyWebs = basicDetails2.companyWebsite;
                    } else {
                        companyWebs = https.concat(
                            basicDetails2.companyWebsite
                        );
                    }
                    dispatch(
                        addSocialAction({
                            socialName: "Website",
                            socialLink: companyWebs,
                        })
                    );
                }
                dispatch(
                    savePayment({
                        paymentId: result?.paymentIntent?.id,
                        created: result?.paymentIntent?.created,
                        status: result?.paymentIntent?.status,
                        currency: result?.paymentIntent?.currency,
                        subscriptionName: type,
                        paymentMethodType:
                            result?.paymentIntent?.payment_method_types[1],
                        payment_method: result?.paymentIntent?.payment_method,
                        description: result?.paymentIntent?.description,
                        amount: result?.paymentIntent?.amount,
                    })
                );
                console.log("You got money!");
                onNext();
                setLoading(false);
            } else {
                if (payload?.success) {
                    stripe
                        .confirmCardPayment(client_secret)
                        .then(async (result) => {
                            if (result?.error) {
                                console.log("error key andar");
                                toast({
                                    title: "Error",
                                    description: "Payment Failure",
                                    status: "error",
                                    duration: 3000,
                                    isClosable: true,
                                    position: "top-right",
                                });
                                console.log(result.error);
                                setLoading(false);
                                navigate("/paymentreject");
                            } else {
                                // await dispatch(addCompany(data));
                                // await dispatch(getUserProfile());
                                dispatch(
                                    setCost({
                                        cost:
                                            selectedSub?.subscription_cost -
                                            discount,
                                    })
                                );
                                navigate("/paymentsuccess");
                                if (basicDetails2.companyWebsite?.length > 1) {
                                    const http = "http://";
                                    const https = "https://";
                                    let companyWebs = "";
                                    var value = basicDetails2.companyWebsite;
                                    if (
                                        value.startsWith(http) ||
                                        value.startsWith(https)
                                    ) {
                                        companyWebs =
                                            basicDetails2.companyWebsite;
                                    } else {
                                        companyWebs = https.concat(
                                            basicDetails2.companyWebsite
                                        );
                                    }
                                    dispatch(
                                        addSocialAction({
                                            socialName: "Website",
                                            socialLink: companyWebs,
                                        })
                                    );
                                }
                                dispatch(
                                    savePayment({
                                        paymentId: result?.paymentIntent?.id,
                                        created: result?.paymentIntent?.created,
                                        status: result?.paymentIntent?.status,
                                        currency:
                                            result?.paymentIntent?.currency,
                                        subscriptionName: type,
                                        paymentMethodType:
                                            result?.paymentIntent
                                                ?.payment_method_types[1],
                                        payment_method:
                                            result?.paymentIntent
                                                ?.payment_method,
                                        description:
                                            result?.paymentIntent?.description,
                                        amount: result?.paymentIntent?.amount,
                                    })
                                );
                                console.log("You got money!");
                                onNext();
                                setLoading(false);
                            }
                        });
                } else {
                    navigate("/paymentreject");
                }
            }
        } else {
            console.log("This is for 0 amount");
            setLoading(true);
            const res = await makePaymentAPIWithCredits({
                email: user?.email,
                type: type,
                applyCredit: promoApplied,
            });
            const payload = res?.data;

            if (
                payload?.status === "succeeded" &&
                (payload?.trial_going_on || payload?.appliedCredit)
            ) {
                // await dispatch(addCompany(data));
                await dispatch(getUserProfile());
                console.log("YOOOOOOOO");

                navigate("/paymentsuccess");
            } else {
                navigate("/paymentreject");
            }
        }
    };
   

    const applyPromoCode = async (e) => {
        e.preventDefault();
        try {
            const response = await applyPromoCodeAPI({ coupon_code: promo });

            if (response.status === 200) {
                // setpromo("");
                setpromoError("");
                dispatch(getUserProfile());
                toast({
                    title: response.data.message,
                    position: "top-right",
                    isClosable: true,
                    duration: 3000,
                    status: "success",
                });
                setPromoApplied(true);
                await dispatch(getUserProfile());
                dispatch(detailsActions.applyPromoCodeStatus());
                dispatch(detailsActions.setActiveStep(3));
                dispatch(detailsActions.setIsFree(false));
                dispatch(detailsActions.setPlan("Annual"));
                navigate("/details?type=Annual")
            }
        } catch (e) {
            setpromoError(e.response.data.message);
            setPromoApplied(false);
        }
    };

    useEffect(() => {
        if (type) {
            let temp = subscriptions.find((i) => i?.subscription_name === type);
            setSelectedSub(temp);
        }
    }, []);

    useEffect(() => {
        if (user?.subCredit > 0) {
            if (user?.subCredit * 100 >= selectedSub?.subscription_cost) {
                setDiscount(selectedSub?.subscription_cost);
                setPromoApplied(true);
                setPassed(true);
            } else {
                setDiscount(user?.subCredit * 100);
                setPromoApplied(true);
            }
        }
    }, [user, selectedSub]);

    return (
        <>
            <div className="container py-4">
                <div>
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
                            <div className="payment-form container">
                                {selectedSub?.subscription_name ===
                                    "Annual" && (
                                    <form
                                        className="promo-code"
                                        // onSubmit={applyPromoCode}
                                    >
                                        <label>Enter promo code</label>
                                        <div className="flex gap-2 items-center continue-button">
                                            <input
                                                type="text"
                                                placeholder="Enter code here"
                                                className="promo-code-input"
                                                value={promo}
                                                onChange={(e) =>
                                                    setpromo(e.target.value)
                                                }
                                            />
                                            <Button
                                                onClick={applyPromoCode}
                                                color="blue"
                                                type="submit"
                                                buttonType="filled"
                                                size="regular"
                                                ripple="light"
                                                disabled={promo?.length < 10}
                                                className="mb-3"
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                        <p
                                            style={{
                                                display:
                                                    promoError.length > 0
                                                        ? "block"
                                                        : "none",
                                            }}
                                            className="poppins font-normal text-sm text-red"
                                        >
                                            {promoError}
                                        </p>
                                    </form>
                                )}
                                <div className="d-flex">
                                    <div className="form-group p-0">
                                        <h5 className="element-title">
                                            Card Number
                                        </h5>
                                        <div className="inputBox">
                                            <AiOutlineCreditCard />
                                            <CardNumberElement className="input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="form-group p-0">
                                        <h5 className="element-title">
                                            Name of the card holder
                                        </h5>
                                        <div className="inputBox">
                                            <AiOutlineIdcard />
                                            <input
                                                className="input"
                                                type="text"
                                                placeholder="Name on Card"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="col-8 p-0">
                                        <h5 className="element-title">
                                            Valid Thru
                                        </h5>
                                        <div className="inputBox">
                                            <BsCalendarEvent />
                                            <CardExpiryElement className="input" />
                                        </div>
                                    </div>
                                    <div className="col-4 ps-2 pe-0">
                                        <h5 className="element-title">CVV</h5>
                                        <div className="inputBox">
                                            <MdVpnKey />
                                            <CardCvcElement
                                                className="input"
                                                onChange={() => setPassed(true)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-12 mx-auto left-bar">
                            <div className="details-heading">
                                <h2>Payment Details</h2>

                                <div className="row">
                                    <div className="col-8">Selected Plan:</div>
                                    <div className="col-4">
                                        {selectedSub?.subscription_name}
                                    </div>
                                    <div className="col-8">Start date:</div>
                                    <div className="col-4">
                                        {moment().format("ll")}
                                    </div>
                                    <div className="col-8">Total Price:</div>
                                    <div className="col-4">
                                    $ {
                                            selectedSub?.subscription_name ==
                                            "Annual" && discount>0 ? (selectedSub?.subscription_price-discount)?.toFixed(2) :  selectedSub?.subscription_price?.toFixed(2)
                                        }
                                        
                                    </div>
                                    <div className="col-8">Discount:</div>
                                    <div className="col-4">
                                        {selectedSub?.subscription_name ===
                                        "Annual"
                                            ? `${user?.subCredit>0 ? "Applied":"0 - month free credit"}`
                                            : "0 - month free credit"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row total-row mt-4">
                                    <div className="col-8">Total Amount:</div>
                                    <div className="col-4">
                                        $
                                        {selectedSub?.subscription_name ===
                                        "Annual"
                                            ? discount>0 ? ((selectedSub?.subscription_price-discount) + (((selectedSub?.subscription_price-discount) * 10) / 100))?.toFixed(2) : (selectedSub?.subscription_cost?.toFixed(2) -
                                              discount)?.toFixed(2)
                                            : selectedSub?.subscription_cost?.toFixed(2)}
                                               &nbsp;
                                       <span className="text-secondary fw-bold" style={{fontSize:"12px"}}> (Incl GST)</span>
                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between buttons mb-5 mt-3">
                        <div className="back-button">
                            <Button
                                onClick={onBack}
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                Back
                            </Button>
                        </div>
                        <div className="continue-button">
                            {
                                loading? <Button
                                
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                   Processing...
                                  </Button>:<Button
                                onClick={submitHandler}
                                color="blue"
                                type="submit"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                {isFree
                                    ? "Confirm"
                                    : loading
                                    ? "Processing"
                                    : "Confirm Payment"}
                                     </Button>
                            }
                            {/* <Button
                                onClick={submitHandler}
                                color="blue"
                                type="submit"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                {isFree
                                    ? "Confirm"
                                    : loading
                                    ? "Processing"
                                    : "Confirm Payment"}
                                     </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// Removing the cards details step for free trail
const FreeTrial = ({ onBack, onNext, data, type }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const [passed, setPassed] = useState(false);

    const { isFree } = useSelector((state) => state?.details);
    const { user } = useSelector((state) => state?.auth);
    const { basicDetails2 } = useSelector((state) => state.details);
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        setLoading(true);
        if (!stripe || !elements) {
            return;
        }
        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: `${user?.firstName} ${user?.lastName}`,
                email: `${user?.email}`,
            },
        });

        const { payload } = await dispatch(
            makePayment({
                payment_method: result?.paymentMethod?.id,
                email: user?.email,
                type: type,
            })
        );
        if (payload?.status === "succeeded") {
            // await dispatch(addCompany(data));
            if (basicDetails2.companyWebsite?.length > 1) {
                const http = "http://";
                const https = "https://";
                let companyWebs = "";
                var value = basicDetails2.companyWebsite;
                if (value.startsWith(http) || value.startsWith(https)) {
                    companyWebs = basicDetails2.companyWebsite;
                } else {
                    companyWebs = https.concat(basicDetails2.companyWebsite);
                }
                dispatch(
                    addSocialAction({
                        socialName: "Website",
                        socialLink: companyWebs,
                    })
                );
            }
            await dispatch(getUserProfile());
            navigate("/profile");
            dispatch(detailsActions.setIsFree(false));
            dispatch(authActions.setIsAuthenticated(true));
            setLoading(false);
        } else {
            toast({
                title: "Error",
                description: "Payment Failure",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            navigate("/paymentreject");

            console.log(result.error);
            setLoading(false);
        }
    };

    return (
        <>
            <div className="payment-form container mt-5">
                <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
                        <div className="card-heading position-relative">
                            <AiOutlineCreditCard
                                fontSize={28}
                                className="card-icon"
                            />
                            <h4>Credit/Debit Card</h4>
                        </div>

                        <div className="type-free-span">
                            <span>
                                Your card will only be charged at the end of the
                                3 month trial unless you choose to cancel your
                                subscription prior.
                            </span>
                        </div>
                        <div className="d-flex">
                            <div className="form-group p-0">
                                <h5 className="element-title">Card Number</h5>
                                <div className="inputBox">
                                    <AiOutlineCreditCard />
                                    <CardNumberElement className="input" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group p-0">
                                <h5 className="element-title">
                                    Name of the card holder
                                </h5>
                                <div className="inputBox">
                                    <AiOutlineIdcard />
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Name on Card"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-8 p-0">
                                <h5 className="element-title">Valid Thru</h5>
                                <div className="inputBox valid-box">
                                    <BsCalendarEvent />
                                    <CardExpiryElement className="input" />
                                </div>
                            </div>
                            <div className="col-4 ps-2 pe-0 cvv-box">
                                <h5 className="element-title">CVV</h5>
                                <div className="inputBox cvv-box">
                                    <MdVpnKey />
                                    <CardCvcElement
                                        className="input"
                                        onChange={() => setPassed(true)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="type-free-button"
                    style={{
                        paddingBottom: "0.1rem",
                    }}
                >
                    <div className="d-flex w-100 justify-content-between buttons mb-5 mt-3">
                        <div className="back-button">
                            <Button
                                onClick={onBack}
                                color="blue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                Back
                            </Button>
                        </div>
                        <div className="continue-button">
                            <Button
                                onClick={submitHandler}
                                color="blue"
                                type="submit"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                disabled={!passed}
                            >
                                {isFree && !loading ? "Confirm" : "Processing"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
